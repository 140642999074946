import React, { Component } from "react";

// Lib
import { Redirect, withRouter } from "react-router-dom";
import Iframe from 'react-iframe';

// Component
import Spinner from "../../Spinner";
import ErrorModal from "../../ErrorModal";

// Services
import i18n from '../../../i18n';
import ConsentRequestService from "../../Services/ConsentRequestService";
import IDPConnectService from "../../Services/IDPConnectService";
import {generateRandomString, generateCodeChallenge} from "../../../utils/OAuthUtils"

class ConsentQRContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceAuth: {},
      codeVerifier: null,
      redirectPage: null,
      transaction_id: ConsentRequestService.currentRequestToken(),
      client: ConsentRequestService.currentRequestClient(),
      sp_info: ConsentRequestService.getSPinfo(),
      client_id: window.config.client_id,
      redirectUrl: "",
      hasError: false,
      errorTitle: "",
      errorMessage: "",
      errorButtonText: i18n.t("ok"),
      isLoginFailed: this.props.isLoginFailed,
      isInternetDisconnected: this.props.isInternetDisconnected
    };
  }

  componentDidMount = async () => {
    const { transaction_id, client_id, sp_info } = this.state;

    const codeVerifier = generateRandomString(64);
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    const formattedParams = {
      "scope": `${window.config.url}/tx/${transaction_id}`, 
      "client_id": client_id,
      "code_challenge":codeChallenge,
      "code_challenge_method": "S256"
    }

    await this.handleIdpListRender().catch(e => e)

    let deviceAuth = {};

    deviceAuth = await ConsentRequestService.getStoredDeviceAuthorizaton().catch(e => e)
 
    //deviceAuth has user_code which is needed to inject information into the QR Code Generator
    if (!deviceAuth || (deviceAuth.transaction_id && deviceAuth.transaction_id !== transaction_id)) {
      deviceAuth = await ConsentRequestService.deviceAuthorizaton(formattedParams, transaction_id).catch(e => e)
    }

    const redirectPage = IDPConnectService.fetchLoginPage("qr_consent", deviceAuth, sp_info?.name);

    this.setState({
      deviceAuth,
      redirectPage,
      codeVerifier
    });

    window.addEventListener("message", IDPConnectService.handleReceivePostMessage, false);
    
    // Call the function so that it fetch first time right after mounting the component
    this.handleQrPolling(transaction_id);
    
    // After Polling
    this.intervalQrPolling = setInterval(this.handleQrPolling, Number(deviceAuth.interval) * Number(1000));
  }

  componentWillUnmount = () => {
    clearInterval(this.intervalQrPolling);
    window.addEventListener("message", IDPConnectService.handleReceivePostMessage, false);
  }


  handleIdpListRender = async () => {
    const { isLoginFailed } = this.state;

    let errorTitle = "";
    let errorMessage = "";
    let hasError = false;

    if(isLoginFailed) {
      hasError = true
      errorTitle = i18n.t("login__logging_error_title");
      errorMessage = i18n.t("login__logging_error_desc");
    }
    
    this.setState({
      errorTitle,
      errorMessage,
      hasError,
    })
  }

  handleQrPolling = async () => {
    const { transaction_id, deviceAuth, client_id, codeVerifier } = this.state;

    const formattedParams = {
      device_code: deviceAuth.device_code,
      client_id,
      grant_type: "urn:ietf:params:oauth:grant-type:device_code",
      code_verifier: codeVerifier,
    };

    try {
      // Polling with device/token
      const deviceToken = await ConsentRequestService.deviceToken(formattedParams)

      if (deviceToken.access_token) {
        clearInterval(this.intervalQrPolling);
        
        // Check if redirect available 

        const result = await ConsentRequestService.redirectAfterPermissionSubmit(transaction_id, deviceToken.access_token);
  
        if (result.status === 200 && result.data?.error && result?.data?.error === "MISSING_PERMISSION_CODE") {
          // If token received fetch tx details and redirect to confirm authorization page
          const txDetails = await ConsentRequestService.getTransactionDetails(transaction_id, deviceToken.access_token);
          
          this.props.history.push({
            pathname: window.GLOBAL_PATH+"confirm-authorization",
            state: {
              request: txDetails,
              deviceToken
            }
          })
        } else {
          this.setState({
            redirectUrl: result.data.redirect_url
          })
        }
      } 
    } catch (e) {
      if (e.data?.error === "invalid_request") {
        this.props.history.push(window.GLOBAL_PATH+"request-error");
      }
      return;
    }
  }

  handleToggleErrorModal = () => {
    // Clearing browser state 
    window.history.replaceState("state", null);
    window.localStorage.clear();
    this.setState({ isLoginFailed: false, hasError: false })
  }


  render() {
    const {
      transaction_id,
      redirectUrl,
      hasError,
      errorTitle,
      errorMessage,
      isLoginFailed,
      redirectPage,
      errorButtonText
    } = this.state;
   
    if (transaction_id === null) {
      return <Redirect to="/" />;
    }

    if (redirectUrl) {
      window.location.assign(redirectUrl);
    }

    return (
      <div className="qr-wrapper">
        {
          redirectPage
          ?
          <Iframe 
            url={redirectPage}
            width="100%"
            height="100%"
            display="block" 
            title={i18n.t("consentqr__page_title")}
          />
          :
          <div className="loader-wrapper">
            <Spinner />
          </div>
        }

        {
          (hasError || isLoginFailed)
          &&
          <ErrorModal 
            isOpen={hasError || isLoginFailed}
            errorTitle={errorTitle}
            errorMessage={errorMessage} 
            errorButtonText={errorButtonText}
            handleToggleModal={this.handleToggleErrorModal}
          />
        }
      </div>
    );
  }
}

export default withRouter(ConsentQRContainer);