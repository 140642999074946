//eslint-disable-next-line
import React, { Component } from "react";

// Components
import Spinner from "../../Spinner";

// Services
import IDPConnectService from "../../Services/IDPConnectService";
import { BrowserStorage } from "../../Services/BrowserStorage";
import i18n from "../../../i18n";

// Lib
import Iframe from 'react-iframe';

class LoginContainer extends Component {

  state = {
    redirectTo: null
  }

  componentDidMount = async () => {
    const loginPage = IDPConnectService.fetchLoginPage("login", '', '',  BrowserStorage.get("idp_cancelled"), BrowserStorage.get("idp_cancelled_error"));
    BrowserStorage.remove("idp_cancelled");
    BrowserStorage.remove("idp_cancelled_error");
    this.setState({ redirectTo: loginPage  });

    window.addEventListener("message", IDPConnectService.handleReceivePostMessage, false);
  }

  componentWillUnmount = () => {
    window.removeEventListener("message", IDPConnectService.handleReceivePostMessage, false);
  }

  render() {

    const {
      redirectTo
    } = this.state;

    return (
      <div className="login-in-wrapper">
        {
          redirectTo
          ?
          <Iframe 
            url={redirectTo}
            title={i18n.t("login__login_title")}
            width="100%"
            height="100%"
            display="block"
          />
          :
          <div className="loader-wrapper">
            <Spinner />
          </div>
        }
      </div>
    )
  }
}

export default LoginContainer;
