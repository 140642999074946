import WellKnownApi from "../components/Services/WellKnownAPI";

export async function generateOAuthUrl(
  scopePrefix: "idp" | "ds",
  identifier: string,
  responseType: "code" | "none" = "code"
) {
  const clientId = window.config.client_id; 
  const scope = `${scopePrefix} ${identifier}`;
  const state = generateRandomString(16);
  const codeVerifier = generateRandomString(64);
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  const {authorization_endpoint} = await WellKnownApi.getOpenIdConfiguration()

  let oauthUrl =
    `${authorization_endpoint}?` +
    `client_id=${encodeURIComponent(clientId)}&` +
    `response_type=${encodeURIComponent(responseType)}&` +
    `redirect_uri=${encodeURIComponent(getRedirectUri(scopePrefix))}&` +
    `state=${encodeURIComponent(state)}&` +
    `scope=${encodeURIComponent(scope)}&`;

  if (codeChallenge) {
    oauthUrl +=
      `code_challenge_method=S256&` +
      `code_challenge=${encodeURIComponent(codeChallenge)}`;
  }

  return { oauthUrl, state, codeVerifier };
}

export function getRedirectUri(scopePrefix: "idp" | "ds"){
  const base_url = "https://" + window.location.hostname;

  const redirectUri = scopePrefix === "idp" ? `${base_url}/idp-callback` : `${base_url}/rs-callback`;

  return redirectUri
}

export async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hash = await crypto.subtle.digest('SHA-256', data);
    return base64UrlEncode(hash);
}

export function base64UrlEncode(arrayBuffer) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)))
        .replace(/\+/g, '-') // Replace + with -
        .replace(/\//g, '_') // Replace / with _
        .replace(/=+$/, ''); // Remove padding
}

export function generateRandomString(length) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
