import IdpApi from "./IdpAPI";
import {generateOAuthUrl} from "../../utils/OAuthUtils"
import { BrowserStorage, SessionStorage } from "./BrowserStorage";

// Servicess
import ConsentRequestService from "./ConsentRequestService";

// Lib
import  { detectDevice } from "../../utils/CommonFunctions.js";

let IDPConnectService = {};

var IDPAfter = Object.freeze({
    auth: "auth",
    consent: "consent",
    wednesday: 3
});

var getEndpoint = function () {
    return window.config.url;
};

IDPConnectService.fetchLoginPage = function (isLoginPage, deviceAuth, sp_name, idpCancelled, idpCancelledError) {
    const baseUrl = getEndpoint();
    if (deviceAuth) {
        return `${baseUrl}/login/idps?dark_mode=false&page=${isLoginPage}&theme=${window.config.theme}&user_code=${deviceAuth.user_code}&verification_uri_complete=${deviceAuth.verification_uri_complete}&sp_name=${sp_name}&platform=${detectDevice()}`;
    } else {
        return `${baseUrl}/login/idps?dark_mode=false&page=${isLoginPage}&theme=${window.config.theme}&platform=${detectDevice()}&idp_cancelled=${idpCancelled}&error=${idpCancelledError}`;
    }
};

IDPConnectService.ListIdps = function() {
    return new Promise((resolve, reject) => {
        IdpApi
            .getIdps()
            .then(result => {
                    resolve(result)
                }
            ).catch(e => {
                reject(e)
            })
    })
};

/**
 * Handles iframe post messages from a wallet login theme or a thymeleaf template.
 * 
 * To use, add this handler to the window's message event listener in your component's 
 * componentDidMount method:
 * window.addEventListener("message", IDPConnectService.handleReceivePostMessage, false);
 * 
 * Ensure to remove the event listener in the componentWillUnmount method:
 * window.removeEventListener("message", IDPConnectService.handleReceivePostMessage, false);
 * 
 * @param {MessageEvent} event - The message event object, automatically passed when the function is referenced.
 * @returns {void}
 */
IDPConnectService.handleReceivePostMessage = function(event) {
    if (event.origin !== window.config.url) {
      return;
    }
    const payload = JSON.parse(event.data);

    BrowserStorage.set("idp_after_type", payload.idp_after_type);
    BrowserStorage.set("idp_id", payload.idp_id);
    BrowserStorage.set("idp_request_id", payload.idp_request_id);
    BrowserStorage.set("idp_request_secret", payload.idp_request_secret);
    BrowserStorage.set("idp_last_view_name", payload.idp_last_view_name);

    IDPConnectService.initiateLogin(payload.idp_id);
  };

IDPConnectService.initiateLogin = async function (idpAuthenticatorId) {
  const { oauthUrl, state, codeVerifier } = await generateOAuthUrl(
    "idp",
    idpAuthenticatorId
  );

  BrowserStorage.set("idp_id", idpAuthenticatorId);

  sessionStorage.setItem("oauthState", state);
  sessionStorage.setItem("codeVerifier", codeVerifier);

  window.location.href = oauthUrl;
};

IDPConnectService.LoginToIdpCallback = function(callBackState, callBackCode) {
    const oauthState = SessionStorage.get("oauthState")
    const codeVerifier = SessionStorage.get("codeVerifier")

    if (!oauthState || !codeVerifier) {
        return Promise.reject("pending idp login request missing or incomplete");
    }

    if (oauthState !== callBackState) {
        return Promise.reject("pending idp login request state mismatch")
    }

    return new Promise((resolve, reject) => {
        let idp_after_type = BrowserStorage.get("idp_after_type");
        let idp_id = BrowserStorage.get("idp_id");
        let last_view = BrowserStorage.get("idp_last_view_name");
        let currentTransactionID = SessionStorage.get("cr_token");

        IdpApi.requestTokenExchangeIdp(callBackCode, codeVerifier).then(
            async (result) => {

                

                if (result.access_token) {
                    BrowserStorage.set("session", result.access_token);
                }
                
                if (last_view === "CONSENT_QR") {
                    try {
                        await ConsentRequestService.receiveRequestWithToken(currentTransactionID);
                    } catch (e) {
                        reject(e)
                    }
                }

                let redirectTo = "profile";
                
                // Q: Is this to do a token refresh?
                const tokenHistory = ConsentRequestService.tokenHistory();
                
                if ((tokenHistory && tokenHistory !== "null" && tokenHistory !== "undefined" && Object.keys(tokenHistory).length) || (idp_after_type === IDPAfter.consent)) {
                    redirectTo = `consent/${currentTransactionID}`;
                }

                // Case for 403 when deleting an account
                if (BrowserStorage.get("delete_reason")) {
                    redirectTo = "settings";
                }

                result.internal_redirect = window.GLOBAL_PATH+redirectTo;
                result.last_view = last_view;

                resolve(result);

                // verified-me error state to be passed to iframe
                if(result.error) {
                    BrowserStorage.set("idp_cancelled", idp_id)
                    BrowserStorage.set("idp_cancelled_error", result.error)
                }

                // clean up tranasction state
                BrowserStorage.remove("idp_id")
                BrowserStorage.remove("idp_request_id");
                BrowserStorage.remove("idp_request_secret");
                BrowserStorage.remove("idp_after_type");
                BrowserStorage.remove("idp_last_view_name");
            },
            error => {
                error.last_view = last_view;
                reject(error)
            }
        );
    });
};

export default IDPConnectService;
