import {
    SessionStorage
} from "./BrowserStorage";

import TransactionAPI from "./TransactionAPI";

import { AuthService } from "./AuthService";
import IDPConnectService from "./IDPConnectService";

import { searchObjByKeyValue } from "../../utils/CommonFunctions";

let ConsentRequestService = {};

/**
    @param token The incoming request token

    @return Where to redirect the user
*/

// !!!: better way to inject url base into request
var getEndpoint = function () {
    return window.config.url;
};

ConsentRequestService.receiveRequestWithToken = function (token, client, resource) {
    // Get the token history
    let transactionDic = ConsentRequestService.tokenHistory();

    // Add to session storage
    SessionStorage.set("cr_token", token);
    SessionStorage.set("client", client);

    return new Promise(async (resolve, reject) => {
        // Redirect to Consent Page with transaction details
        if (AuthService.isAuthenticated()) {
            TransactionAPI.getDetailsUser(token).then(
                result => {              
                    // Add to Token history dictionary
                    transactionDic = {
                        ...transactionDic,
                        [result.transaction_id]: result
                    }

                    SessionStorage.set("cr_token_history", JSON.stringify(transactionDic));
                    
                    resolve(window.GLOBAL_PATH+`consent/${token}`);
                },
                error => {
                    reject(error)
                }
            );
        } else {
            let sp_info;
            // Get Service Provider Info by client id
            try {
                sp_info = await ConsentRequestService.getClientInfoByClientID(client);
            } catch (error) {
                sp_info = { name: "" };
            }

            SessionStorage.set("sp_info", JSON.stringify(sp_info));
            // resource determines if it should be auto idp (skip /consent-request page) or /consent-request (standard login routing)
            const idp_triggering_resource = window.config.auto?.auto_idp_triggering_resource;
            const redirectLink = (( idp_triggering_resource && (resource?.includes(idp_triggering_resource))) && (window.config.auto?.autoStartIdp)) ? await IDPConnectService.LoginToIdp(window.config.auto?.autoStartIdp, 'consent', 'CONSENT_QR', true) : (window.GLOBAL_PATH+"consent-request");
            resolve(redirectLink)
        }
    });
};

ConsentRequestService.getTransactionDetails = function (token, device_access_token) {
    return new Promise((resolve, reject) => {
        TransactionAPI.getDetails(token, device_access_token).then(
            result => {
                resolve(result);
            },
            error => reject(error)
        );
    });
};

ConsentRequestService.tokenHistory = function () {
    return JSON.parse(SessionStorage.get("cr_token_history"));
};

ConsentRequestService.currentRequestDetailsFromTokenHistory = function (transactionID, tokenHistory) {
    let filteredCurrentDetails = Object.keys(tokenHistory).filter(item => item === transactionID);

    if (filteredCurrentDetails.length) {
        filteredCurrentDetails = tokenHistory[filteredCurrentDetails[0]];
    }

    return filteredCurrentDetails;
}

ConsentRequestService.currentRequestToken = function () {
    return SessionStorage.get("cr_token");
};

ConsentRequestService.currentRequestClient = function () {
    return SessionStorage.get("client");
};

ConsentRequestService.getSPinfo = function () {
    const sp_info = SessionStorage.get("sp_info");

    if (sp_info) {
        return JSON.parse(sp_info);
    }
};

ConsentRequestService.getStoredDeviceAuthorizaton = function () {
    return new Promise((resolve, reject) => {
        const result = JSON.parse(SessionStorage.get("deviceAuthorizaton"));
        resolve(result)
    });
};

ConsentRequestService.deviceAuthorizaton = (params, transaction_id) => {
    return new Promise((resolve, reject) => {
        TransactionAPI.deviceAuthorizaton(params).then(
            result => {
                result.transaction_id = transaction_id;
                SessionStorage.set("deviceAuthorizaton", JSON.stringify(result));

                resolve(result);
            },
            error => reject(error)
        );
    });
};

ConsentRequestService.deviceToken = (params) => {
    return new Promise((resolve, reject) => {
        TransactionAPI.deviceToken(params).then(
            result => {
                resolve(result);
            },
            error => reject(error)
        );
    });
};


ConsentRequestService.completeWithPermissions = function (transaction_id, permissions) {
    if (transaction_id === null) {
        return Promise.reject("no pending request");
    }

    const tokenHistory = ConsentRequestService.tokenHistory();

    return new Promise((resolve, reject) => {
        TransactionAPI.postPermissions(transaction_id, permissions).then(
            result => {
                delete tokenHistory[transaction_id];

                SessionStorage.set("cr_token_history", JSON.stringify(tokenHistory));

                resolve(result);
            },
            error => reject(error)
        );
    });
};

ConsentRequestService.redirectAfterPermissionSubmit = function (transaction_id, accessToken, permission_code, connectingDsID) {
    return new Promise((resolve, reject) => {
        TransactionAPI.redirectAfterPermissionSubmit(transaction_id, accessToken, permission_code).then(
            async (result) => {
                SessionStorage.remove("client");
                SessionStorage.remove("sp_info");
                SessionStorage.remove("cr_token");

                // Nested flow 
                if (connectingDsID && connectingDsID !== "null" && connectingDsID !== "undefined") {
                    const tokenHistory = ConsentRequestService.tokenHistory();

                    const filteredValue = await Promise.resolve(searchObjByKeyValue("connectingDsID", tokenHistory, connectingDsID));

                    SessionStorage.set("cr_token", filteredValue.transaction_id);
                }

                resolve(result);
            },
            error => reject(error)
        );
    });
};

ConsentRequestService.CancelTransaction = (transaction_id) => {
    const baseUrl = getEndpoint();

    return `${baseUrl}/tx/${transaction_id}/cancel`;
};

ConsentRequestService.getClientInfoByClientID = (clienID) => {
    return new Promise((resolve, reject) => {
        TransactionAPI.getClientInfoByClientID(clienID).then(
            result => {
                resolve(result);
            },
            error => reject(error)
        );
    });
};

ConsentRequestService.checkForDisabledExpiredAcc = (request, filteredDsAccounts) => {
    return (
        request.requested_resources.map(re_res => {
            filteredDsAccounts.map(account => {
                if (re_res.dataSource) {
                    if (account.ds_account_id === re_res.dataSource.ds_account_id) {
                        if (account.expired === true || account.disabled) {
                            delete re_res['dataSource'];
                        }
                    }
                }

                return account;
            })

            return re_res;
        })
    )
};

ConsentRequestService.checkForSourceModalOpen = (request) => {
    if (request.requested_resources) {
        return (
            request.requested_resources
                .find(req_res => SessionStorage.get(`${req_res.resource_definition.res_def_id}-${request.transaction_id}`) === "true")
        )
    }
};

ConsentRequestService.getRequestHistory = function (request) {
    if (request && request.transaction_id) {
        return SessionStorage.get(`request_history-${request.transaction_id}`);
    }
};

ConsentRequestService.handleAutoPopulate = function (req_res, account, isSourceModalOpen) {
    if (!req_res.dataSource && (!SessionStorage.get("isAutoPopulatedOnLoad") || SessionStorage.get("isAutoPopulatedOnLoad") === "false") && !isSourceModalOpen) {
        req_res.dataSource = account.data_source;
        req_res.dataSource.ds_account_id = account.ds_account_id;
        req_res.dataSource.nickname = account.nickname;
    }

    return req_res;
};

ConsentRequestService.setRequestHistoryToken = function (request_transaction_id, request) {
    SessionStorage.set(`request_history-${request_transaction_id}`, JSON.stringify(request));
};

export default ConsentRequestService;
