import axios from "axios";

export type OpenIdConfigurationResponse = {
    authorization_endpoint: string;
    token_endpoint: string;
    issuer: string;
    jwks_uri: string;
    response_types_supported: string[];
    grant_types_supported: string[];
    code_challenge_methods_supported: string[];
    token_endpoint_auth_methods_supported: string[];
    subject_types_supported: string[];
    userinfo_endpoint: string;
    request_uri_parameter_supported: boolean;
}

export const WellKnownApi = {

    getOpenIdConfiguration(): Promise<OpenIdConfigurationResponse> {
        return new Promise((resolve, reject) => {
            // Check for cached data in session storage
            const cachedResponse = sessionStorage.getItem("WellKnownResponse");
            if (cachedResponse) {
                return resolve(JSON.parse(cachedResponse));
            }

            axios.get("/.well-known/openid-configuration", {
                baseURL: getEndpoint(),
            })
            .then(res => {
                // Cache the response in session storage
                sessionStorage.setItem("WellKnownResponse", JSON.stringify(res.data));
                resolve(res.data);
            })
            .catch(error => reject(error.response));
        });
    }
};

function getEndpoint(): string {
    return window.config.url;
}

export default WellKnownApi;
