import axios from "axios";
import { AuthService } from "./AuthService";

let DatasourceAPI: any = {};

// !!!: better way to inject url base into request
var getEndpoint = function() {
  return window.config.url;
};

DatasourceAPI.getDatasources = function() {
  return new Promise((resolve, reject) => {
    axios
      .get("/data-sources", { 
        baseURL: getEndpoint(),
        params: { type: "RS" },
        headers: {
          Authorization: AuthService.authorizationHeader()
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

DatasourceAPI.connectionRequestState = function(
  dsid
) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/me/ds-accounts/${dsid}`, {
        baseURL: getEndpoint(),
        headers: {
          Authorization: AuthService.authorizationHeader(),
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

export default DatasourceAPI;
