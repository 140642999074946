import axios from "axios";
import { getRedirectUri } from "../../utils/OAuthUtils";
import WellKnownApi from "./WellKnownAPI";

let IdpAPI:any = {};

// !!!: better way to inject url base into request
var getEndpoint = function() {
    return window.config.url;
};

IdpAPI.getIdps = function() {
  return new Promise((resolve, reject) => {
    axios
      .get("/login/idps", { baseURL: getEndpoint() })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error.response));
  });
};

IdpAPI.tokenRevocation = async function(access_token: string) {
    const {token_endpoint} = await WellKnownApi.getOpenIdConfiguration()
    
    return new Promise((resolve, reject) => {
        axios
            .post(`${token_endpoint}/revoke`, {
                token: access_token,
                client_id: window.config.client_id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(res => resolve(res.data))
            .catch(error => reject(error.response));
    });
}

IdpAPI.requestTokenExchangeIdp = async function (code, codeVerifier) {
    const {token_endpoint} = await WellKnownApi.getOpenIdConfiguration()

    return new Promise((resolve, reject) => {
        axios
            .post(token_endpoint, {
                grant_type: "authorization_code",
                code: code,
                redirect_uri: getRedirectUri("idp"),
                code_verifier: codeVerifier,
                client_id: window.config.client_id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => resolve(res.data))
            .catch(error => reject(error.response));
    });
};


export default IdpAPI;
